import { AreaSelectList, BranchSelectList } from 'utils/codelookup';
import {
  DefaultDiv,
  GridHeader,
  GridHeaderItem,
  type GridHeaderItemProps,
  GridRefetch,
} from 'styles/style';
import { Input } from 'components/common/Input/Input';
import {
  StyledFormItem,
  StyledInputDate,
  StyledSelect,
  StyledSelectInput,
} from 'components/common/test/Styled.ant';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';

const ChargingStationGridHeader = ({
  queryState,
  setQueryState,
  refetch,
  reload,
  setAreaNo,
  areaNo,
}: any) => {
  const handleQueryChange = (field: string, value: any) => {
    setQueryState({
      ...queryState,
      [field]: value,
    });
  };

  const gridHeaderData: GridHeaderItemProps[] = [
    {
      type: 'radio',
      label: '구분',
      value: queryState.org,
      onChange(e: any) {
        handleQueryChange('org', e.target.value);
      },
      listData: [
        {
          label: '전체',
          value: '',
        },
        {
          label: '직영점',
          value: 'STT_DIR',
        },
        {
          label: '자영점',
          value: 'STT_FRN',
        },
        {
          label: 'EV사업팀',
          value: 'EV_DIV',
        },
      ],
    },
    {
      type: 'radio',
      label: '운영',
      value: queryState.status,
      onChange(e: any) {
        handleQueryChange('status', e.target.value);
      },
      listData: [
        {
          label: '전체',
          value: '',
        },
        {
          label: '운영',
          value: 'active',
        },
        {
          label: '정지',
          value: 'inactive',
        },
      ],
    },
    {
      type: 'radio',
      label: '세차장',
      value: queryState.wash,
      onChange(e: any) {
        handleQueryChange('wash', e.target.value);
      },
      listData: [
        {
          label: '전체',
          value: '',
        },
        {
          label: '있음',
          value: 'Y',
        },
        {
          label: '없음',
          value: 'N',
        },
      ],
    },
    {
      type: 'select',
      label: '지역',
      value: queryState.region,
      onChange(value: any) {
        handleQueryChange('region', value);
      },
      listData: [
        {
          label: '전체',
          value: '',
        },
        {
          label: '서울',
          value: '서울',
        },
        {
          label: '경기도',
          value: '경기도',
        },
        {
          label: '인천',
          value: '인천',
        },
        {
          label: '강원',
          value: '강원',
        },
        {
          label: '충북',
          value: '충북',
        },
        {
          label: '세종',
          value: '세종',
        },
        {
          label: '대전',
          value: '대전',
        },
        {
          label: '충남',
          value: '충남',
        },
        {
          label: '전북',
          value: '전북',
        },
        {
          label: '광주',
          value: '광주',
        },
        {
          label: '전남',
          value: '전남',
        },
        {
          label: '경북',
          value: '경북',
        },
        {
          label: '대구',
          value: '대구',
        },
        {
          label: '울산',
          value: '울산',
        },
        {
          label: '경남',
          value: '경남',
        },
        {
          label: '부산',
          value: '부산',
        },
        {
          label: '제주',
          value: '제주',
        },
      ],
    },
  ];

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && refetch) {
      refetch();
    }
  };

  return (
    <DefaultDiv>
      <GridRefetch refetch={refetch} reload={reload} />
      <GridHeader container grid>
        <StyledSelectInput>
          <StyledSelect
            value={queryState.searchKey}
            onChange={(value) => {
              // console.log(value);
              setQueryState({
                ...queryState,
                searchKey: value,
              });
            }}
          >
            <Select.Option value="">전체</Select.Option>
            <Select.Option value="chgs_station_id">충전소ID</Select.Option>
            <Select.Option value="chgs_name">충전소명</Select.Option>
            <Select.Option value="address">주소</Select.Option>
            {/* <Select.Option value="chgs_kepco_meter_no">
              한국전력고지번호
            </Select.Option> */}
            <Select.Option value="chgs_operator_manager_id">
              현장담당자
            </Select.Option>
          </StyledSelect>
          <Input
            value={queryState.searchVal}
            onChange={(event) => {
              setQueryState({
                ...queryState,
                searchVal: event.target.value,
              });
            }}
            onKeyDown={handleKeyPress}
          />
        </StyledSelectInput>
        <StyledInputDate iNumber={2}>
          <label htmlFor="">생성일</label>

          <div>
            <DatePicker
              format="YYYY-MM-DD"
              picker="date"
              placeholder="YYYY-MM-DD"
              value={
                queryState?.startDate ? dayjs(queryState?.startDate) : null
              }
              onChange={(value) => {
                // console.log(dayjs(value).format('YYYY-MM-DD'));
                // console.log(value);
                setQueryState({
                  ...queryState,
                  startDate: value ? dayjs(value).format('YYYY-MM-DD') : '',
                });
              }}
            />
          </div>
          <div>
            <DatePicker
              format="YYYY-MM-DD"
              picker="date"
              placeholder="YYYY-MM-DD"
              value={queryState?.endDate ? dayjs(queryState?.endDate) : null}
              onChange={(value) => {
                // console.log(dayjs(value).format('YYYY-MM-DD'));
                setQueryState({
                  ...queryState,
                  endDate: value ? dayjs(value).format('YYYY-MM-DD') : '',
                });
              }}
            />
          </div>
        </StyledInputDate>
        <AreaSelectList
          value={queryState.area}
          onChange={(e: any) => {
            setAreaNo(e);
            setQueryState({
              ...queryState,
              area: e,
              branch: '',
            });
          }}
        />
        <BranchSelectList
          value={queryState.branch}
          areaNo={areaNo}
          onChange={(e: any) => {
            setQueryState({
              ...queryState,
              branch: e,
            });
          }}
        />

        {gridHeaderData.map((item, index) => {
          return (
            <GridHeaderItem
              key={index}
              type={item.type}
              label={item.label}
              value={item.value}
              onChange={item.onChange}
              placeholder={item?.placeholder}
              listData={item?.listData}
            />
          );
        })}
      </GridHeader>
    </DefaultDiv>
  );
};

export default ChargingStationGridHeader;
